import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

//Actions
import {
  apiPostReservation,
  clearDataReservation,
  setVehiclesState,
  validateFormReservation,
} from '../../../../common/modules/vehicles/VehiclesActions';

//Components
import { AsyncImage } from '../../components/AsyncImage';
import CheckboxInput from '../../components/CheckboxInput';
import SelectInput from '../../components/SelectInput';
import TextInput from '../../components/TextInput';

//Resources
import {refreshSelectPicker, startSelectPicker} from '../../resources/styles/js/custom';
import { getAsset } from '../../../../common/resources/assets/assets';
import { strings } from '../../../../common/resources/locales/i18n';
import VehiclesManager from '../../../../common/modules/vehicles/VehiclesManager';
import Routing from '../routing/Routing';
import ContactManager from '../../../../common/modules/contact/ContactManager';
import InfoReceiveCommunications from '../contact/modals/InfoReceiveCommunications';
import InfoStatisticalAnalysis from '../contact/modals/InfoStatisticalAnalysis';

class ModalReservation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      readMore: false,
    }
  }

  componentDidMount() {
    this._clearDataReservation();
    startSelectPicker()
  }

  render() {
    const {
      concessionaire, email, error, name, 
      submit, surnames, phone, vehicle, island, islands, dataDealers, isLoadingReservation,
      comunicationsAccepted, analysisAccepted, rgpdPrivacyPolicyLink
    } = this.props;

    const {readMore} = this.state;

    const nameVehicle = VehiclesManager.getName(vehicle);
    const modelGroupDescription = VehiclesManager.getModelGroupDescription(vehicle);
    const images = VehiclesManager.getImagesList(vehicle);
    const gearbox = VehiclesManager.getGearboxDescription(vehicle);
    const fuel = VehiclesManager.getFuelDescription(vehicle);
    const modelYear = VehiclesManager.getModelYear(vehicle);
    const colorGroupDescription = VehiclesManager.getColorDescription(vehicle);


    return (
      <>
        <div className="modal fade" id="modalReservation" tabIndex="-1">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title">
                  {strings('vehicleDetails.wantThis', { vehicle: modelGroupDescription })}
                </div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body border-bottom">

                {/* FICHA MODELO */}
                <div id="selected-model" className="row d-flex align-items-center py-md-2">
                  {this.renderImage(images, modelGroupDescription)}
                  <div className="col-md-7 pl-md-4">
                    <div className="h5 small-lg text-uppercase text-dark">{strings('vehicleDetails.selectedModel')}</div>
                    <div className='h4'>
                      {strings('vehicleDetails.hyundai')}
                      <span className="font-weight-bold">{nameVehicle}</span>
                    </div>
                    <ul className="list-inline">
                      {modelYear ?
                        <li className="list-inline-item mr-md-3">
                          <ion-icon name="calendar-outline"></ion-icon>
                          {' ' + modelYear}
                        </li> : 
                        <></>
                      }
                      {fuel ?
                        <li className="list-inline-item mr-md-3">
                          <ion-icon name="flash-outline"></ion-icon>
                          {' ' + fuel}
                        </li> : 
                        <></>
                      }
                      {gearbox ? 
                        <li className="list-inline-item mr-md-3">
                          <ion-icon name="cog-outline"></ion-icon>
                          {' ' + strings('vehicleDetails.gearbox', { gearbox: gearbox.toLowerCase() })}
                        </li> : 
                        <></>
                      }
                      {colorGroupDescription ? 
                        <li className="list-inline-item">
                          <ion-icon name="color-filter-outline"></ion-icon>
                          {' ' + colorGroupDescription}
                        </li> :
                        <></>
                      }
                    </ul>
                  </div>
                </div>

              </div>

              <form onSubmit={(e) => this._onSubmit(e)}>
                <div className="modal-body p-lg-4">
                  {/* Start legal text */}
                  <label className="h5">Solicitud de información</label>
                  <span>
                    <span className="d-block h6"> <u> Información básica sobre Protección de Datos: </u> <br/></span>
                    Responsable: HYUNDAI CANARIAS S.L.U {' '}
                    <a href="https://www.hyundai.com/canarias/es/utilidades/politica-de-privacidad?_ga=2.122191691.1759595897.1632903868-372755064.1632903868" target="_blank" rel='noreferrer'>
                      (+Info)<br/>
                    </a>
                    Finalidad: Gestión de consultas y solicitudes<br/>de información. {' '}
                    <a href="https://www.hyundai.com/canarias/es/utilidades/politica-de-privacidad" target="_blank" rel='noreferrer'>
                      (+Info)<br/>
                    </a>
                    Derechos: puede ejercitar su derecho de acceso, rectificación, supresión y otros, tal como aparece en la información ampliada que puede conocer visitando nuestra {' '}
                    <a href="https://www.hyundai.com/canarias/es/utilidades/politica-de-privacidad" rel='noreferrer' target="_blank">
                      política de privacidad
                    </a>.
                  </span>
                  {/* End legal text */}
                  <div className="h6 my-3">{strings('vehicleDetails.personalInformation')}:</div>
                  <div className="form-row">

                    <TextInput
                      styleContainer='col-md-6 padding-modal-reservation'
                      isFormGroup={true}
                      textLabel={strings('placeholder.name')}
                      id='name'
                      type='text'
                      placeholder={strings('placeholder.name')}
                      value={name}
                      onChange={(e) => this._handleValueInput(e.target.id, e.target.value)}
                      error={error}
                      submit={submit}
                    />

                    <TextInput
                      styleContainer='col-md-6 padding-modal-reservation'
                      isFormGroup={true}
                      textLabel={strings('placeholder.surnames')}
                      id='surnames'
                      type='text'
                      placeholder={strings('placeholder.surnames')}
                      value={surnames}
                      onChange={(e) => this._handleValueInput(e.target.id, e.target.value)}
                      error={error}
                      submit={submit}
                    />

                    <TextInput
                      styleContainer='col-md-6 padding-modal-reservation'
                      isFormGroup={true}
                      textLabel={strings('placeholder.email')}
                      id='email'
                      type='text'
                      placeholder={strings('placeholder.email')}
                      value={email}
                      onChange={(e) => this._handleValueInput(e.target.id, e.target.value)}
                      error={error}
                      submit={submit}
                    />

                    <TextInput
                      styleContainer='col-md-6 padding-modal-reservation'
                      isFormGroup={true}
                      textLabel={strings('placeholder.phone')}
                      id='phone'
                      type='text'
                      placeholder={strings('placeholder.phone')}
                      value={phone}
                      onChange={(e) => this._handleValueInput(e.target.id, e.target.value)}
                      error={error}
                      submit={submit}
                    />

                    <SelectInput
                      title={strings('placeholder.island')}
                      dataChildren={ContactManager.getIslandForDealer(islands, dataDealers)}
                      showSelect={true}
                      titleSelect={strings('placeholder.selectIsland')}
                      id='island'
                      value={island}
                      onChange={(e) => {
                        this._handleValueInput(e.target.id, e.target.value)
                        refreshSelectPicker()
                      }}
                      error={error}
                      submit={submit}
                      styleContainer={'col-md-6'}
                      getItemId={true}
                    />

                    <SelectInput
                      title={strings('placeholder.concessionaire')}
                      id='concessionaire'
                      dataChildren={ContactManager.getDealerForIsland(dataDealers, island)}
                      value={concessionaire}
                      onChange={(e) => this._handleValueInput(e.target.id, e.target.value)}
                      titleSelect={strings('placeholder.selectConcessionaire2')}
                      showSelect={true}
                      error={error}
                      submit={submit}
                      styleContainer={'col-md-6'}
                      getItemId={true}
                    />
                  </div>

                  {/* Start legal text */}
                  <div className={`mt-3 mb-2 ${readMore ? 'd-none' : 'd-block'}`}>
                    <span className={`${readMore ? 'd-none' : 'd-block'}`}>
                      <span className='font-weight-bold'>¿Quieres ser parte del Ecosistema de Domingo Alonso Group*? {' '}</span>
                      <Link 
                        replace 
                        to={Routing.nowhere} 
                        onClick={() => this._handleValueChangeView('readMore', !readMore)} 
                      >
                        Ver más.
                      </Link>
                    </span>
                  </div>
                  <div className={`mt-3 mb-2 ${readMore ? 'd-block' : 'd-none'}`}>
                    <span className={`${readMore ? 'd-block' : 'd-none'}`}>
                      <span className='font-weight-bold'>¿Quieres ser parte del Ecosistema de Domingo Alonso Group*? {' '}</span>
                      Para DOMINGO ALONSO GROUP, tú, nuestro cliente, eres el centro de lo que hacemos. Queremos contar contigo 
                      para mejorar nuestros servicio y ofrecerte cada día mejores ofertas y promociones, incluso personalizadas,
                      para invitarte a eventos y actividades y en general mantenerte informado sobre la actividad de nuestro grupo. {' '}
                      <Link 
                        replace 
                        to={Routing.nowhere}
                        onClick={() => this._handleValueChangeView('readMore', !readMore)} 
                      >
                        Ocultar.
                      </Link>
                    </span>
                  </div>

                  <div className="form-group my-4">
                    <CheckboxInput
                      containLabel={strings('contact.receiveCommercialCommunications')}
                      id='comunicationsAccepted'
                      error={error}
                      extraElement={<>&nbsp;<i onClick={() => setTimeout(() => document.getElementById('open-modal-receive-communications')?.click(), 500)} data-dismiss="modal" className="bi bi-info-circle-fill"></i></>}
                      submit={submit}
                      checked={comunicationsAccepted}
                      onChange={(e) => this._handleValueInput(e.target.id, e.target.checked)}
                      notCheck={true}
                    />
                    <CheckboxInput
                      containLabel={strings('contact.customCommercialCommunications')}
                      id='analysisAccepted'
                      error={error}
                      submit={submit}
                      extraElement={<>&nbsp;<i onClick={() => setTimeout(() => document.getElementById('open-modal-statistical-analysis')?.click(), 500)} data-dismiss="modal" className="bi bi-info-circle-fill"></i></>}
                      checked={analysisAccepted}
                      onChange={(e) => this._handleValueInput(e.target.id, e.target.checked)}
                      notCheck={true}
                    />
                  </div>

                  <span>
                    <span className="d-block h6"> <u> Información básica sobre Protección de Datos: </u> <br/></span>
                    Responsable: DOMINGO ALONSO GROUP S.L. {' '}
                    <a href={rgpdPrivacyPolicyLink} target="_blank" rel='noreferrer'>
                      (+Info)<br/>
                    </a>Finalidad: Envío de información publicitaria y elaboración de perfiles comerciales. {' '}
                    <a href={rgpdPrivacyPolicyLink} target="_blank" rel='noreferrer'>
                      (+Info)<br/>
                    </a>
                    Derechos: puede ejercitar su derecho de acceso, rectificación, supresión y otros, tal como aparece en la información ampliada que puede conocer en nuestra web {' '}
                    <a href="https://www.domingoalonsogroup.com/" target="_blank" rel='noreferrer'>
                      www.domingoalonsogroup.com
                    </a>.
                  </span>
                  <strong className='d-block mt-3'>*Este apartado no es indispensable para prestarle los servicios solicitados.</strong>
                  {/* End legal text */}

                  

                </div>
                <div className="modal-footer">

                  <button type="button" className="btn btn-outline-secondary btn-lg px-4" data-dismiss="modal">
                    {strings('button.cancel')}
                  </button>


                  <button type="submit" className="btn btn-primary btn-lg px-4" disabled={isLoadingReservation} style={{ maxHeight: 50, minWidth: 180 }}>
                    {isLoadingReservation
                      ? <div className="d-flex align-items-center justify-content-center">
                        <div className="spinner-border" role="status" aria-hidden="true" style={{ width: 25, height: 25 }} />
                      </div>
                      : null
                    }
                    {!isLoadingReservation ? strings('button.sendRequest2') : ''}
                  </button>

                </div>
              </form>
            </div>
          </div>
        </div>
        <button 
          className='d-none'
          data-toggle="modal" 
          data-target="#modalInfoRecibirComunicaciones"
          id='open-modal-receive-communications'
        />
        <button 
          className='d-none'
          data-toggle="modal" 
          data-target="#modalInfoAnalisisEstadisticos"
          id='open-modal-statistical-analysis'
        />
        <InfoReceiveCommunications openModalReservation />
        <InfoStatisticalAnalysis openModalReservation />
      </>
    )
  }

  renderImage(images, modelGroupDescription) {
    if (images && images.length !== 0) {
      return (
        <AsyncImage
          source={images[0]}
          alt={modelGroupDescription}
          style='img-fluid'
          styleContainer='col-md-4 mb-4 mb-md-0'
          srcPlaceholder={getAsset('icPlaceholderModel')}
          minHeightLoading='184px'
        />
      )
    }
  }

  /** PRIVATE METHODS **/
  _handleValueInput(prop, value) {
    const { setVehiclesState, submit, validateFormReservation } = this.props;
    setVehiclesState({ prop, value });
    if (submit) validateFormReservation();
  }

  _handleValueChangeView(prop, value) {
    this.setState({[prop]: value});
  }

  _onSubmit(e) {
    e.preventDefault();
    const { vehicle } = this.props;
    const vehicleId = VehiclesManager.getProductId(vehicle);
    this.props.apiPostReservation(vehicleId, (response) => this._redirectSuccess(response));
  }

  _redirectSuccess = async (response) => {
    const { history } = this.props;
    history.push({
      pathname: Routing.msgConfirmation,
      state: { message: response }
    });
  };

  _clearDataReservation() {
    this.props.clearDataReservation();
  }

}

const mapStateToProps = ({ AppReducer, VehiclesReducer }) => {
  const { islands, dataDealers, rgpdPrivacyPolicyLink } = AppReducer
  const { concessionaire, email, error, name, rgpd, submit, surnames, phone, island, isLoadingReservation, comunicationsAccepted, analysisAccepted } = VehiclesReducer;

  return {
    islands, dataDealers, rgpdPrivacyPolicyLink,
    concessionaire, email, error, name, rgpd, submit, surnames, phone, island, isLoadingReservation, comunicationsAccepted, analysisAccepted
  };
};

const mapStateToPropsAction = {
  apiPostReservation,
  clearDataReservation,
  setVehiclesState,
  validateFormReservation,
};

export default connect(mapStateToProps, mapStateToPropsAction)(ModalReservation);
