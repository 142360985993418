import Config from "react-environment";
import {
  addCustomHeadSEO,
} from '../../web/src/resources/styles/js/ScriptsHyundai';
import VehiclesManager from '../../common/modules/vehicles/VehiclesManager';
import Routing from "../../web/src/module/routing/Routing";

export const isApp = () => {
  return Config.REACT_APP_IS_APP === 'true'
}

export const isDev = () => {
  return Config.REACT_APP_IS_DEV === 'true'
}

export const isPro = () => {
  return Config.REACT_APP_IS_PRO === 'true'
}

export const isWeb = () => {
  return Config.REACT_APP_IS_WEB === 'true'
}
export const showEnvName = () => {
  if (isDev()) {
    console.log('*************')
    console.log(Config.REACT_APP_NAME)
    console.log('*************')
  }
}

export const formatToPrice = (data) => {
  if (data) {
    const options1 = { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 };
    const numberFormat1 = new Intl.NumberFormat('es-ES', options1);
    return numberFormat1.format(data)
  }
}

export const parseStringVar = (string) => {
  let arr = string.split('_');
  let result = '';

  for (let i = 0; i < arr.length; i++) {
    if (i !== 0) {
      let str = arr[i].toLowerCase();
      arr[i] = str.charAt(0).toUpperCase() + str.slice(1);
    }
    result += arr[i];
  }
  return result;
}

export const parseSelect = (data) => {
  const result = []

  data.map((item) => {
    result.push({ id: item.value_id, value: item.value_label })
  })

  return result;
}

/* LEGALS SCRIPTS */
export const legalScripts_Default = async (props) => {
  const { metasHomeTitle, metasHomeDescription } = props;

  addCustomHeadSEO(
    'default', { metasHomeTitle, metasHomeDescription }
  );
}

export const legalScripts_VehiclesContainer = async (props) => {
  const { metasModelTitle, currentModelDescription, metasModelDescription, allVehiclesMetadesc, allVehiclesMetatitle, idParams, isPromotion, promoVehiclesMetadesc, promoVehiclesMetatitle } = props;

  addCustomHeadSEO(
    'vehicles_container', { metasModelTitle, currentModelDescription, metasModelDescription, allVehiclesMetadesc, allVehiclesMetatitle, idParams, isPromotion, promoVehiclesMetadesc, promoVehiclesMetatitle }
  );
}

export function getVinPixelFacebook(data) {
  let content_ids = [];
  if (data && data.length > 0 && Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      content_ids.push(VehiclesManager.getVin(data[i]));
    }
    return getStringArrayPxFb(content_ids);
  } else if (data && data.vin) {
    content_ids.push(VehiclesManager.getVin(data));
    return getStringArrayPxFb(content_ids);
  }
  return getStringArrayPxFb(content_ids);
}

export function getStringArrayPxFb(array) {
  let string = '[';
  if (array && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      if (i === array.length - 1) {
      string += `'` + array[i] + `']`;
      } else {
        string += `'` + array[i] + `',`;
      }
    }
    return string;
  }
  return string + ']';
}

export function addPixelFacebook(isAppContainer, clickImInterested, content_ids) {
  $(document).ready(function () {
    if (clickImInterested) {
      //search
      let script_addToWishlist = document.createElement('script');
      script_addToWishlist.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '881432012535672');fbq('track', 'AddToWishlist', {content_type: 'vehicle', content_ids: ${content_ids}});`;
      script_addToWishlist.id = 'fbq-addToWishlist';
      //add search
      document.head.appendChild(script_addToWishlist);
    }
    const location = window.location.pathname;
    if (location && !clickImInterested) {

      if (isAppContainer) {
        //pageView
        let script_pageView = document.createElement('script');
        script_pageView.innerText = `!function (f, b, e, v, n, t, s) {if (f.fbq) return; n = f.fbq = function () {n.callMethod ?n.callMethod.apply(n, arguments) : n.queue.push(arguments)};if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';n.queue = []; t = b.createElement(e); t.async = !0;t.src = v; s = b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t, s)}(window, document, 'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '595652818527082');fbq('track', 'PageView');`;
        //add pageView
        document.head.appendChild(script_pageView);

        /* noscript */
        let noscript = document.createElement('noscript');
        let img = document.createElement('img');
        img.width = '1';
        img.height = '1';
        img.style = 'display:none';
        img.src = 'https://www.facebook.com/tr?id=595652818527082&ev=PageView&noscript=1';
        noscript.append(img);
        /* add noscript */
        document.head.appendChild(noscript);
      }

      //all scripts
      const allScripts = document.getElementsByTagName('script');
      let numScript = [];
      for (let i = 0; i < allScripts.length; i++) {
        if (
          allScripts[i].id === 'fbq-search' ||
          allScripts[i].id === 'fbq-viewcontent' ||
          allScripts[i].id === 'fbq-lead' ||
          allScripts[i].id === 'fbq-addToWishlist'
        ) {
          numScript.push(i);
        }
      }
      for (let i = 0; i < numScript.length; i++) {
        document.getElementsByTagName('script')[numScript[i]].remove();
      }

      if (location.includes('search=') || location.includes('/vehiculos/')) {
        //search
        let script_search = document.createElement('script');
        script_search.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '881432012535672');fbq('track', 'Search', {content_type: 'vehicle', content_ids: ${content_ids}});`;
        script_search.id = 'fbq-search';
        //add search
        document.head.appendChild(script_search);
      } else if (location.includes('/vehiculo/')) {
        //content (vehicle detail)
        let script_viewContent = document.createElement('script');
        script_viewContent.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '881432012535672');fbq('track', 'ViewContent', {content_type: 'vehicle', content_ids: ${content_ids}});`;
        script_viewContent.id = 'fbq-viewcontent';
        //add viewContent
        document.head.appendChild(script_viewContent);
      } else if (location.includes('/mensaje-confirmacion')) {
        //lead
        let script_lead = document.createElement('script');
        script_lead.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '881432012535672');fbq('track', 'Lead');`;
        script_lead.id = 'fbq-lead';
        //add lead 
        document.head.appendChild(script_lead);
      }
    }

  })
}

const keyParamsAllow = [
  'utm_medium',
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

export const getQueryParamsUTM = (pathname) => {
  if (pathname) {
    const queryParams = pathname.split('?');
    
    if (queryParams?.length > 1) {
      const params = queryParams[1];
      const arrParams = params.split('&');
      
      if (arrParams?.length > 0) {
        let keyParam = '';
        for (let i = 0; i < arrParams.length; i++) {
          keyParam = arrParams[i].split('=');
          if (keyParam?.length > 0) {
            if (!keyParamsAllow.includes(keyParam[0])) {
              arrParams[i] = '';
            }
          } else arrParams[i] = '';
        }
      }
      
      let queryParamsVerify = '';
      for (let i = 0; i < arrParams.length; i++) {
        if (arrParams[i] && i > 0) {
          queryParamsVerify += '&' + arrParams[i];
        } else if (arrParams[i]) {
          queryParamsVerify += '?' + arrParams[i];
        }
      }
      
      return !queryParamsVerify ? '' : queryParamsVerify;
    }
  }
  
  return '';
}

export const parseQueryParamsUTM = ({pathname, search, history}) => {
  if (pathname && search && history) {
    const queryParams = getQueryParamsUTM(search);
    
    if (queryParams) {
      history.replace(pathname + queryParams);
    }
  }
}

export const pixelConversionLeads = (typeScript) => {
  const allScripts = document.getElementsByTagName('script');
  let numScript = [];
  for (let i = 0; i < allScripts.length; i++) {
    if (
      allScripts[i].id === 'pcl-home' ||
      allScripts[i].id === 'pcl-thanks'
    ) {
      numScript.push(i);
    }
  }
  for (let i = 0; i < numScript.length; i++) {
    document.getElementsByTagName('script')[numScript[i]].remove();
  }

  switch (typeScript) {
    case Routing.home:
      let scriptHome = document.createElement('script');
      scriptHome.innerText = `var ExpireDays=90;function SetCookie(c,d,a){var e=new Date,b=new Date;(null==a||0==a)&&(a=1),b.setTime(e.getTime()+864e5*a),document.cookie=c+"="+escape(d)+"; expires="+b.toGMTString()+"; path=/"}for(i=0,thevars=(qstr=(qstr=document.location.search).substring(1,qstr.length)).split("&");i<thevars.length;i++)switch((cookiecase=thevars[i].split("="))[0]){case"sfmc_sub":SetCookie("SubscriberID",sfmc_sub=cookiecase[1],ExpireDays);break;case"e":SetCookie("EmailAddr_",e=cookiecase[1],ExpireDays);break;case"j":SetCookie("JobID",j=cookiecase[1],ExpireDays);break;case"l":SetCookie("ListID",l=cookiecase[1],ExpireDays);break;case"jb":SetCookie("BatchID",jb=cookiecase[1],ExpireDays);break;case"u":SetCookie("UrlID",u=cookiecase[1],ExpireDays);break;case"mid":SetCookie("MemberID",mid=cookiecase[1],ExpireDays)}`;
      scriptHome.id = 'pcl-home';
      document.head.appendChild(scriptHome);
      break;

    case Routing.msgConfirmation:
      let scriptThanks = document.createElement('script');
      scriptThanks.innerText = `var convid="1",displayorder="1",linkalias="singularf",dataset='<data amt="1" unit="Downloads" accumulate="true" />';function SetCookie(e,i,l){var o=new Date,a=new Date;(null==l||0==l)&&(l=1),a.setTime(o.getTime()+864e5*l),document.cookie=e+"="+escape(i)+"; expires="+a.toGMTString()+"; path=/"}function getCookie(e){return document.cookie.length>0&&-1!=(startC=document.cookie.indexOf(e+"="))?(startC+=e.length+1,-1==(endC=document.cookie.indexOf(";",startC))&&(endC=document.cookie.length),unescape(document.cookie.substring(startC,endC))):null}var jobid=getCookie("JobID"),emailaddr=getCookie("EmailAddr_"),subid=getCookie("SubscriberID"),listid=getCookie("ListID"),batchid=getCookie("BatchID"),urlid=getCookie("UrlID"),memberid=getCookie("MemberID");void 0==subid&&(subid="",emailaddr=void 0),void 0==emailaddr&&(emailaddr="");let elImgPixel=document.createElement("img");elImgPixel.src="http://click.s50.exacttarget.com/conversion.aspx?xml=<system><system_name>tracking</system_name><action>conversion</action>",elImgPixel.src+="<member_id>"+memberid+"</member_id>",elImgPixel.src+="<job_id>"+jobid+"</job_id>",elImgPixel.src+="<sub_id>"+subid+"</sub_id>",elImgPixel.src+="<email>"+emailaddr+"</email>",elImgPixel.src+="<list>"+listid+"</list>",elImgPixel.src+="<BatchID>"+batchid+"</BatchID>",elImgPixel.src+="<original_link_id>"+urlid+"</original_link_id>",elImgPixel.src+="<conversion_link_id>"+convid+"</conversion_link_id>",elImgPixel.src+="<link_alias>"+linkalias+"</link_alias>",elImgPixel.src+="<display_order>"+displayorder+"</display_order>",elImgPixel.src+="<data_set>"+dataset+"</data_set>",elImgPixel.src+="</system>",elImgPixel.width="1",elImgPixel.height="1",document.body.appendChild(elImgPixel);`;
      scriptThanks.id = 'pcl-thanks';
      document.head.appendChild(scriptThanks);
      break;

    default:
      break;
  }
}
