import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// Actions
import {apiGetAppConfig, apiGetDealers, setAppState} from '../../../../common/modules/app/AppActions';

// Resources
import {addPixelFacebook, isPro, pixelConversionLeads, showEnvName} from '../../../../common/utils/Utils';
import Router from '../routing/Router';
import {getAssets} from '../../../../common/resources/assets/assets';
import { loadChatDev } from '../../resources/styles/js/ScriptChatDev';
import { loadChatPro } from '../../resources/styles/js/ScriptChatPro';
import { functionShowModalBanner } from '../../resources/styles/js/custom';
import Routing from '../routing/Routing';
import { addCookies, addMetricas } from '../../resources/styles/js/ScriptsHyundai';

class AppContainer extends Component {
  state={
    isLoadingLocal:true
  }

  async componentDidMount() {

    const pathname = window.location.pathname;
    showEnvName()
    this.setState({isLoadingLocal: true})
    await getAssets();
    await this._apiGetAppConfig();
    await this._apiGetDealers();

    const { metricsGtmEnabled, metricsGtmId, cookiesBannerScript } = this.props;
    if (addMetricas && metricsGtmEnabled === '1') addMetricas(metricsGtmId);
    if (addCookies) addCookies({cookiesBannerScript});
    if (pixelConversionLeads) pixelConversionLeads(pathname);

    if (isPro()) {
      if(loadChatPro) loadChatPro();
      /* Pixel Facebook */
      if (addPixelFacebook) addPixelFacebook(true, false);
    } else {
      if(loadChatDev) loadChatDev();
    }
    
    this.setState({isLoadingLocal: false});
    

    const { popupShowAfterSeconds, popupEnabled, popupHomeOnly, showModalBannerTimestamp} = this.props;
    if (!showModalBannerTimestamp || moment().diff(moment(showModalBannerTimestamp), 'days') >= 1) {
      this._setAppState('showModalBannerTimestamp', moment().format());
      if (functionShowModalBanner && popupEnabled === '1') {
        if (popupHomeOnly === '0' || (popupHomeOnly === '1' && pathname === Routing.home)) {
          functionShowModalBanner({time: popupShowAfterSeconds + '000'});
          this._setAppState('showModalBanner', false);
        } else {
          if (popupHomeOnly === '1' && pathname !== Routing.home) {
            this._setAppState('showModalBanner', true);
          }
        }
      }
    }

    this.setState({isLoadingLocal: false})
  }

  render() {
    const {isLoading} = this.props;
    const {isLoadingLocal} = this.state;

    if (isLoading || isLoadingLocal){
      //TODO USE LAODING COMPONENT
      return (
        <div id="loader">
          <div className="spinner-border text-primary" role="status"/>
        </div>
      )
    }

    return (<Router/>)
  }

  /** PRIVATE METHOD **/
  async _apiGetAppConfig() {
    await this.props.apiGetAppConfig();
  }

  async _apiGetDealers() {
    await this.props.apiGetDealers();
  }

  _setAppState(prop, value) {
    this.props.setAppState({prop, value});
  }
}

const mapStateToProps = ({ AppReducer }) => {
  const { isLoading, popupShowAfterSeconds, popupEnabled, 
    popupHomeOnly, showModalBannerTimestamp, metricsGtmEnabled, 
    metricsGtmId, cookiesBannerScript, } = AppReducer;

  return {
    isLoading, popupShowAfterSeconds, popupEnabled, 
    popupHomeOnly, showModalBannerTimestamp, metricsGtmEnabled, 
    metricsGtmId, cookiesBannerScript,
  };
};

const mapStateToPropsAction = {
  apiGetAppConfig,
  apiGetDealers,
  setAppState,
};

export default connect(mapStateToProps, mapStateToPropsAction)(AppContainer);
